<template>
  <div>
    <wd-navbar class="tarbar">
      <div slot="left" class="middle" @click="$goBack">
        <wd-icon name="arrow-left" />
      </div>
      <div style="white-space: nowrap">
        <span style="white-space: nowrap">{{
          equipInfo.equipmentName || '--'
        }}</span>
      </div>
    </wd-navbar>
    <div>
      <div>
        <!--        <span style="margin-left: 15px; font-size: 14px; padding: 10px 0; display: block; color: #fa4350">查询对象：{{equipInfo.equipmentName || '&#45;&#45;'}}</span>-->
        <wd-datetime-picker
          type="date"
          v-model="searchObject.startDate"
          :default-value="searchObject.startDate"
          label="开始时间"
        ></wd-datetime-picker>
        <wd-datetime-picker
          type="date"
          v-model="searchObject.endDate"
          :default-value="searchObject.endDate"
          label="结束时间"
          :before-confirm="beforeConfirm"
        ></wd-datetime-picker>
      </div>
      <wd-cell title="用量合计">
        <font style="font-size: 18px; color: #fa4350"
          >{{ dataInfo.totalUsage || '0'
          }}<font v-if="equipInfo.equipmentType == '0'">度</font
          ><font v-else>吨</font></font
        >
      </wd-cell>
      <div style="display: flex; align-items: center; justify-content: center">
        <wd-button
          class="button"
          @click="getList"
          :loading="isRequest"
          style="display: flex; justify-content: center; align-items: center"
        >
          <img
            v-if="!isRequest"
            src="../../assets/img/search.svg"
            alt=""
            style="width: 18px; margin-right: 5px"
          />
          搜 索
        </wd-button>
      </div>
    </div>
    <wd-cell-group border>
      <wd-cell title="昨日用量">
        <font style="font-size: 16px; color: #1f6ecf"
          >{{ dataInfo.lastDayUsage || '0'
          }}<font v-if="equipInfo.equipmentType == '0'">度</font
          ><font v-else>吨</font></font
        >
      </wd-cell>
      <wd-cell title="前日用量">
        <font style="font-size: 16px; color: #1f6ecf"
          >{{ dataInfo.lastTwoDayUsage || '0'
          }}<font v-if="equipInfo.equipmentType == '0'">度</font
          ><font v-else>吨</font></font
        >
      </wd-cell>
    </wd-cell-group>
  </div>
</template>

<script>
export default {
  data() {
    return {
      searchObject: {
        startDate: null,
        endDate: null,
      },
      equipInfo: {},
      dataInfo: {},
      isRequest: false,
    }
  },
  created() {
    this.$set(
      this.searchObject,
      'startDate',
      new Date(this.$moment().subtract(3, 'days').format('YYYY-MM-DD'))
    )

    const endDate = new Date()
    endDate.setTime(endDate.getTime() - 24 * 60 * 60 * 1000)
    this.$set(this.searchObject, 'endDate', endDate)

    this.equipInfo = this.$route.params
    console.log(this.equipInfo)
    if (!this.equipInfo.id) {
      this.$router.push('/selectEquip')
    } else {
      if (!this.equipInfo.equipmentName) {
        this.equipInfo.equipmentName = this.equipInfo.acctName
      }
      if (!this.equipInfo.equipmentType) {
        this.equipInfo.equipmentType = this.equipInfo.acctType
      }
      this.getList()
    }
  },
  mounted() {},
  methods: {
    beforeConfirm(value, resolve) {
      const endDate = new Date(value.getTime()).getDate()
      const currDate = new Date().getDate()
      if (endDate >= currDate) {
        resolve(false)
        this.$toast.error('不能选择等于或大于今天的日期')
      } else {
        resolve(true)
      }
    },
    getList() {
      let startDate =
        this.$moment(this.searchObject.startDate).format('YYYY-MM-DD') +
        ' 00:00:00'
      let endDate =
        this.$moment(this.searchObject.endDate).format('YYYY-MM-DD') +
        ' 23:59:59'
      let params = {
        id: this.equipInfo.id,
        startDate: startDate,
        endDate: endDate,
      }

      console.log('isRequest', this.isRequest)

      if (this.isRequest == true) return

      this.$http
        .post('/external/bill', params)
        .then((res) => {
          if (res.code == 200) {
            this.$set(this.dataInfo, 'totalUsage', res.totalUsage)
            this.$set(this.dataInfo, 'lastTwoDayUsage', res.lastTwoDayUsage)
            this.$set(this.dataInfo, 'lastDayUsage', res.lastDayUsage)
          }
        })
        .finally(() => {
          this.isRequest = false
        })
      if (this.isRequest == false) this.isRequest = true
    },
  },
}
</script>

<style lang="less" scoped>
.button {
  /deep/ .wd-button__txt {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.tarbar {
  /deep/ .wd-navbar__title {
    flex: 5;
    white-space: nowrap;
    overflow: visible;
  }
}
</style>
